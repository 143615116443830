@use '@angular/material' as mat;
@use '@ng-matero/extensions' as mtx;

@include mat.core();

$my-primary: mat.define-palette(mat.$grey-palette, 300);
$my-accent: mat.define-palette(mat.$green-palette, 600);

$my-info: mat.define-palette(mat.$blue-palette);
$my-success: mat.define-palette(mat.$green-palette);
$my-warn: mat.define-palette(mat.$orange-palette);
$my-danger: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn,
 ),
 typography: mat.define-typography-config(),
 density: 0,
));

@include mat.all-component-themes($my-theme);
@include mtx.all-component-themes($my-theme);
//------

html,
body {
	height: 100%;
	margin: 0px;
}

app-root,
app-navigation {
	height: 100%;
}

.grid-container {
	height: 100%;
	max-width: 100%;
	margin-top: 0px;
	margin-left: auto;
	margin-bottom: 20px;
	margin-right: auto;
}

//--- Snackbar styles ----------------------------------------

.info {
	background-color: mat.get-color-from-palette($my-info, 500);
	border-color: mat.get-color-from-palette($my-info, 500);
	color: white;
}

.success {
	background-color: mat.get-color-from-palette($my-success, 500);
	border-color: mat.get-color-from-palette($my-success, 500);
	color: white;
}

.warn {
	background-color: mat.get-color-from-palette($my-warn, 200);
	border-color: mat.get-color-from-palette($my-warn, 200);
	color: black;

	.mat-simple-snackbar-action {
		color: black;
	}
}

.danger {
	background-color: mat.get-color-from-palette($my-danger, 500);
	border-color: mat.get-color-from-palette($my-danger, 500);
	color: white;
}

.spacer {
	flex: 1 1 auto;
}

a.title:link {
	color: rgba(0, 0, 0, 0.87); 
	text-decoration: none;
}

a.title:visited 
{
	color: rgba(0, 0, 0, 0.87);
	text-decoration: none;
}

a.title:hover 
{
	color: rgba(0, 0, 0, 0.87);
	text-decoration: underline;
}

.matbuttontoggleicon {
    font-size: 18px;
    margin-top: 6px;
    margin-right: 3px;
}